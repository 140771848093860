import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import { WindowRef } from '../domain/window-ref';
import { HOSTNAME } from "src/app/const/hostname";
import { environment } from "src/environments/environment";
import { DomainService } from "../domain/domain.service";
import { GridResponseService } from "../grid/grid-response.service";


@Injectable()
export class GridInterceptor implements HttpInterceptor {
    constructor(private service: GridResponseService, private domain: DomainService,
        private windowRef: WindowRef) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const hostname = this.windowRef.getHostname();
        if (hostname === HOSTNAME.localhost || environment.mock.enabled) {

            if (this.domain.hasUrl(req.url, environment.gridPaths)) {
                return next.handle(req)
                    .pipe(
                        map(event => {
                            if (event instanceof HttpResponse) {
                                if (event.body) {
                                    const response = this.service.filterResponseByParams(req.params, event.body);
                                    event.body.rows = response.rows;
                                    event.body.totalNoRecords = response.totalNoRecords;
                                    return event.clone({ body: event.body });
                                }
                            }
                        })
                    );
            } else {
                return next.handle(req);
            }
        } else {
            return next.handle(req);
        }

    }
}
