import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AuthService } from './auth/auth.service';
import { NavbarService } from './navbar/navbar.service';
import { DomainService } from './domain/domain.service';
import { HttpErrorComponent } from './http-error/http-error.component';
import { NoConnectionComponent } from './error-page/no-connection.component';
import { PageNotFoundComponent } from './error-page/page-not-found.component';
import { GlobalErrorHandlerService } from './error-handler/global-error-handler.service';
import { HttpErrorService } from './http-error/http-error.service';
import { ForbiddenComponent } from './error-page/forbidden.component';
import { ContextTypeGuard } from './guards/context-type.guard';
import { NavbarComponent } from './navbar/navbar.component';
import { DomService } from './dom/dom.service';
import { GridService } from './grid/grid.service';
import { FlashMessageComponent } from './flash-message/flash-message.component';
import { FlashMessageService } from './flash-message/flash-message.service';
import { LoadingWidgetDirective } from './directives/loading-widget/loading-widget.directive';
import { WindowRef } from './domain/window-ref';
import { ProfileGuard } from './guards/profile.guard';
import { GridComponent } from './grid/grid-component';
import { BaseGridComponent } from './grid/base-grid.component';

@NgModule({ declarations: [
        HttpErrorComponent,
        NoConnectionComponent,
        PageNotFoundComponent,
        ForbiddenComponent,
        LoadingWidgetDirective,
        NavbarComponent,
        FlashMessageComponent,
        GridComponent,
        BaseGridComponent
    ],
    exports: [
        HttpErrorComponent,
        NoConnectionComponent,
        PageNotFoundComponent,
        ForbiddenComponent,
        LoadingWidgetDirective,
        NavbarComponent,
        FlashMessageComponent,
        GridComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA], imports: [CommonModule,
        RouterModule], providers: [
        AuthService,
        GlobalErrorHandlerService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandlerService
        },
        DomainService,
        HttpErrorService,
        NavbarService,
        ContextTypeGuard,
        ProfileGuard,
        DomService,
        GridService,
        FlashMessageService,
        WindowRef,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule { }
