import { Directive, ElementRef, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingWidgetService } from './loading-widget.service';


@Directive({
  selector: '[loading-widget]'
})
export class LoadingWidgetDirective implements OnInit, OnDestroy {
  private subscription$: Subscription = new Subscription();

  constructor(private el: ElementRef, private ref: ChangeDetectorRef, private widget: LoadingWidgetService) { }

  ngOnInit(): void {
    this.el.nativeElement.style.display = 'none';
    this.subscription$ = this.widget.counter$
      .subscribe(count => {
        if (count > 0) {
          this.el.nativeElement.style.display = 'block';
        } else {
          this.el.nativeElement.style.display = 'none';
        }
        this.ref.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }

}
