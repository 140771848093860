import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingWidgetService {

  private _counter = new BehaviorSubject<number>(0);
  public counter$: Observable<number> = this._counter.asObservable();

  constructor() {
  }

  incrementCount() {
    this._counter.next(this._counter.getValue() + 1);
  }

  decrementCount() {
    this._counter.next(this._counter.getValue() - 1);
  }
}
