import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CONTEXT_TYPE } from 'src/app/const/context-type';
import { PATH } from 'src/app/const/path';
import { ProfileService } from '../auth/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ContextTypeGuard  {
  constructor(private router: Router, private pfs: ProfileService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let can = false;
    let profile = this.pfs.profile;
    if (profile) {

      if (profile.type === CONTEXT_TYPE.visma) {
        if (state.url.startsWith(PATH.visma) || state.url.startsWith(PATH.user)) {
          can = true;
        }
      } else if (profile.type === CONTEXT_TYPE.distributor) {
        if (state.url.startsWith(PATH.distributor) || state.url.startsWith(PATH.user)) {
          can = true;
        }
      } else if (profile.type === CONTEXT_TYPE.customerOfVisma) {
        if (state.url.startsWith(PATH.customer) || state.url.startsWith(PATH.user)) {
          can = true;
        }
      } else if (profile.type === CONTEXT_TYPE.user) {
        if (state.url.startsWith(PATH.user)) {
          can = true;
        }
      }
    }

    if (!can) {
      this.router.navigate([PATH.forbidden]);
    }
    return can;
  }

}
