export const DATETIME_FORMAT = {
    en: "dd/MM/yyyy HH:mm:ss",
    no: "dd.MM.yyyy HH:mm:ss",
    nb: "dd.MM.yyyy HH:mm:ss",
    sv: "yyyy-MM-dd HH:mm:ss",
    fi: "d.M.yyyy HH:mm:ss",
    nl: "d-M-yy HH:mm:ss",
    da: "dd/MM/yy HH:mm:ss",
    ro: "dd.MM.yyyy HH:mm:ss"
}
