

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router, NavigationEnd } from '@angular/router';
import nc3navigation from 'src/assets/nc3-navigation-native/nc3navigation';
import { NavbarService } from './navbar.service';

import { DomService } from '../dom/dom.service';
import { AdminWindow } from '../auth/admin-window';
import { XSRF_TOKEN } from 'src/app/const/xsrf-token';
import { WindowRef } from '../domain/window-ref';
import { ProfileService } from '../auth/profile.service';
import { AuthService } from '../auth/auth.service';
import { CONTEXT_TYPE } from 'src/app/const/context-type';
import { environment } from 'src/environments/environment';
import { PATH } from 'src/app/const/path';
import { DomainService } from '../domain/domain.service';
import { LayoutDataService } from 'src/app/layout-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'admin-navbar',
  template: '',
})
export class NavbarComponent implements OnInit, OnDestroy {

  private translationIndex = 'navbar';

  private hashcode$ = new Subscription();
  private logout$ = new Subscription();
  private loginUrl$ = new Subscription();

  constructor(private auth: AuthService, private pfs: ProfileService, private domain: DomainService,
    private service: NavbarService, private dom: DomService,
    private cookieService: CookieService, private layoutDataService: LayoutDataService,
    private router: Router, private windowRef: WindowRef) { }

  ngOnInit() {
    const contextId = String(this.pfs.profile.id);
    const w = (window as AdminWindow & typeof globalThis);

    const language = this.pfs.profile.language;
    this.dom.setLangAttribute(language);
    const env = this.service.getEnviroment(this.windowRef.getHostname());
    const item = this.service.getProfileNavItem(this.pfs.profile, this.windowRef.getHostname());

    let menuItemsEndpoint = 'webapi/rest/vismamenu/v1/items';
    let allowedContextTypes = `${CONTEXT_TYPE.visma},${CONTEXT_TYPE.distributor},${CONTEXT_TYPE.customerOfVisma}`
    if (this.pfs.profile.type === CONTEXT_TYPE.user) {
      menuItemsEndpoint = 'webapi/rest/userdetails/userDetails';
      allowedContextTypes = null;
    }
    
    const navbarOptions = {
      contextId: contextId,
      allowedContextTypes: allowedContextTypes,
      menu: item.menu,
      menuItemsEndpoint: menuItemsEndpoint,
      prefix: this.service.getSelectedAppPrefix(this.pfs.profile.type),
      baseUrl: this.domain.getBaseUrl(this.windowRef.getHostname()),
      language: language,
      xsrfToken: this.cookieService.get(XSRF_TOKEN.cookieName),
      translations: this.pfs.messages[this.translationIndex],
    };

    let walkMeOptions = environment.walkMe[env];
    walkMeOptions.email = this.pfs.profile.email;
    walkMeOptions.type = this.pfs.profile.type;
    walkMeOptions.enabled = this.service.isWalkMeEnabled(this.pfs.profile.walkMeEnable, this.pfs.profile.type, language);

    this.initNavigation(navbarOptions, walkMeOptions, w);

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== '' && event.url !== '/') {
          if (event.url !== PATH.not_found && event.url !== PATH.forbidden) {
            this.dom.setPageTitle(event.url);
            this.layoutDataService.sendPath(event.url);
            this.service.setProfileItems(contextId, event.url);
            nc3navigation.setMenuActiveItems();
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.hashcode$.unsubscribe();
    this.logout$.unsubscribe();
    this.loginUrl$.unsubscribe();
  }


  private initNavigation(navbarOptions: any, walkMeOptions: any, adminWindow: any): void {
    const self = this;
    let options: nc3navigation.Options = {
      baseUrl: navbarOptions.baseUrl,
      restFulPath: {
        menuItems: navbarOptions.menuItemsEndpoint,
        lastLoginTime: 'webapi/rest/vismamenu/getLastLogin',
        itemsSearch: 'webapi/rest/vismamenu/v1/itemsSearch',
        itemsByType: 'webapi/rest/vismamenu/itemsByType'
      },
      language: navbarOptions.language,
      fallbackLanguage: 'en',
      pathFieldName: 'path',
      selectedAppPrefix: navbarOptions.prefix,
      httpHeaders: [
        { name: 'x-xsrf-token', value: navbarOptions.xsrfToken },
        { name: 'Visma-ContextId', value: navbarOptions.contextId }
      ],
      withCredentials: true,
      callback: {},
      allowedContextTypes: navbarOptions.allowedContextTypes,
      firstLevelMenu: navbarOptions.menu.firstLeveLMenu,
      secondLevelMenu: navbarOptions.menu.secondLevelMenu,
      hasFeedback: false,
      hasNotifications: false,
      hasHelp: false,
      translations: navbarOptions.translations,
      usesQueryParams: true,
      limitForTypeaheadResults: 150,
      icons: []
    };

    if (walkMeOptions.enabled) {
      options.icons.push({
        title: 'WalkMe',
        css: 'vismaicon-education',
        onClickCallback: () => {
          if (adminWindow.WalkMePlayerAPI) {
            adminWindow.WalkMePlayerAPI.toggleMenu();
          }
        }
      });
    }

    options.callback = {
      onComponentReady: function () {
        const timestamp = new Date().getTime();
        if (walkMeOptions.enabled) {
          self.dom.createScriptTagWithHashCode(walkMeOptions.scriptUrl, walkMeOptions.hashcode, timestamp, () => {
            const w = (window as AdminWindow & typeof globalThis);
            w.userEmailAddress = walkMeOptions.email;
            w.walkme_get_language = () => {
              return document.documentElement.lang;
            }
          });
        }
      },
      onChangeContextSelector: function (event, item) {
        self.service.removeProfileItems();
        let link = self.service.getRedirectLink(self.windowRef.getHostname(), item);
        self.windowRef.setLocationHref(link);
      },
      onClickMenuItem: function (event, item) {
        self.router.navigateByUrl(item.path)
      },
      onClickLogout: function (event) {
        self.loginUrl$ = self.logout$ = self.auth.logout().subscribe(response => {
          self.service.removeProfileItems();
          self.windowRef.setLocationHref(response.url);
        });
      },
      onError: function (error) {
        if (error.status === 401 || error.status === 403 || error.status === 0) {
          self.loginUrl$ = self.auth.getLoginUrl().subscribe(response => {
            self.windowRef.setLocationHref(response.url);
          });
        }
      }
    }

    nc3navigation.init(options);
  }

}
