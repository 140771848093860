import { PATH } from './path';

export const DISTRIBUTOR_MENU = {
    firstLeveLMenu:
        [
            {
                id: "distributorSupport",
                text: 'menuItemDistributorSupport',
                path: PATH.distributor_support_companymove,
                activeWhen: PATH.distributor_support_companymove,
                shown: true
            }
        ],
    secondLevelMenu: [
        {
            id: 'partnerSupport',
            text: 'menuItemPartnerSupport',
            href: '/admin/supportdistributor/partnersupportcontacts/partnersupportcontactstab/',
            parentId: 'distributorSupport',
            target: '_self',
            shown: true
        },
        {
            id: 'auditors',
            text: 'menuItemAuditors',
            href: '/admin/supportdistributor/auditors/auditorstab/',
            parentId: 'distributorSupport',
            target: '_self',
            shown: true
        },
        {
            id: 'companies',
            text: 'menuItemCompanies',
            href: '/admin/supportdistributor/supportcompany/supportcompanytab/',
            parentId: 'distributorSupport',
            target: '_self',
            shown: true
        },
        {
            id: "companyMove",
            text: 'menuItemCompanyMove',
            path: PATH.distributor_support_companymove,
            activeWhen: PATH.distributor_support_companymove,
            shown: true
        },
        {
            id: 'customers',
            text: 'menuItemCustomers',
            href: '/admin/supportdistributor/supportcustomer/supportcustomertab/',
            parentId: 'distributorSupport',
            target: '_self',
            shown: true
        },
        {
            id: 'customersOfCustomers',
            text: 'menuItemCustomersOfCustomers',
            href: '/admin/supportdistributor/supportcustomerofcustomer/supportcustomerofcustomertab/',
            parentId: 'distributorSupport',
            target: '_self',
            shown: true
        }
    ]
}
