import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api/public_api';
import { GridResponse } from './grid-response';
import { GRID } from 'src/app/const/grid';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GridResponseService {

  constructor() { }

  filterResponseByEvent(event: LazyLoadEvent, response: GridResponse): GridResponse {
    const result = this.filterByEvent(event, response);
    response.totalNoRecords = result.length;
    const rows = result.slice(event.first, event.first + event.rows);
    response.rows = rows;
    return response;
  }

  filterResponseByParams(params: HttpParams, response: GridResponse): GridResponse {
    const result = this.filterByParams(params, response);
    response.totalNoRecords = result.length;
    const start = parseInt(params.get(GRID.param.start));
    const limit = parseInt(params.get(GRID.param.limit));
    response.rows = result.slice(start, start + limit);
    return response;
  }

  filterRowsByFieldValue(field: string, value: string, rows: any[]): GridResponse {
    const result = this.filterByFieldValue(field, value, rows);
    const response: GridResponse = {
      rows: result,
      totalNoRecords: result.length
    }
    return response;
  }

  private sort(arr, sortField, sortOrder) {
    if (sortField) {
      sortOrder = parseInt(sortOrder);
      if (sortOrder === 1) {
        arr.sort((a, b) => a[sortField].localeCompare(b[sortField]));
      } else {
        arr.sort((a, b) => b[sortField].localeCompare(a[sortField]));
      }
    }
  }

  private filterByEvent(event: LazyLoadEvent, response: any): any[] {
    let keys = Object.keys(event.filters);
    let str: string;
    let value: string;
    let matchMode: string;
    const result = response.rows.filter(row => {
      return keys.every((key) => {
        str = event.filters[key].value;
        matchMode = event.filters[key].matchMode;
        if (str === null || str === undefined) {
          return true;
        }

        str = String(str).toLowerCase();
        value = String(row[key]).toLowerCase();

        if (matchMode === 'equals') {
          return value === str;
        } else {
          return value.indexOf(str) > -1;
        }
      });
    });
    this.sort(result, event.sortField, event.sortOrder);
    return result;
  }

  private shouldFilter(key: string): boolean {
    let should = false;
    if (key !== GRID.param.start && key !== GRID.param.limit &&
      key !== GRID.param.sortField && key !== GRID.param.sortOrder &&
      key !== GRID.param.estimatedCount) {
      should = true;
    }
    return should;
  }

  private filterByParams(params: HttpParams, response: any): any[] {
    let str: string;
    let value: string;
    const result = response.rows.filter(row => {
      return params.keys().every((key) => {
        if (this.shouldFilter(key)) {
          str = params.get(key).toLowerCase();
          value = String(row[key]).toLowerCase();
          return value.indexOf(str) > -1;
        } else {
          return true;
        }
      });
    });
    this.sort(result, params.get('sortField'), params.get('sortOrder'));
    return result;
  }

  private filterByFieldValue(field: string, value: string, rows: any): any[] {
    const tmp = JSON.parse(JSON.stringify(rows));
    let result;
    if (value) {
      value = value.toLowerCase();
      result = tmp.filter(row => {
        return row[field].toLowerCase().indexOf(value) > -1;
      });
    } else {
      result = tmp;
    }
    return result;
  }

}
