import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { DomainService } from '../domain/domain.service';
import { WindowRef } from "../domain/window-ref";

@Injectable()
export class MockInterceptor implements HttpInterceptor {
    constructor(private domain: DomainService, private windowRef: WindowRef) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const index = this.domain.isMockRequest(req.url);

        if (index > -1) {
            const root = this.domain.getMockRoot(index);
            const hostname = this.windowRef.getHostname();
            let newUrl: string;
            if (root) {
                newUrl = this.domain.getMockUrl(req.url, hostname, root);
            } else {
                newUrl = this.domain.getV1MockUrl(req.url, hostname);
            }

            const clone = req.clone({
                method: 'GET',
                withCredentials: false,
                url: newUrl
            });
            return next.handle(clone);
        } else {
            return next.handle(req);
        }
    }
}
