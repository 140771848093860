import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ProfileService } from '../auth/profile.service';
import { SESSION_STORAGE_KEY } from 'src/app/const/session-storage-key';

@Component({
  templateUrl: './forbidden.component.html',
})
export class ForbiddenComponent implements OnInit {

  private translationIndex = 'forbidden';
  message: any;

  constructor(private pfs: ProfileService, private location: Location) { }

  ngOnInit() {
    sessionStorage.removeItem(SESSION_STORAGE_KEY.adminUIPath);
    this.message = this.pfs.messages[this.translationIndex];
  }

  goBackToPreviousPage(){
    this.location.back();
  }
}
