export const PAGE_TITLE = {
    '/customer/usersandroles/rolesoverview/companyrolestab': 'Services Access',
    '/customer/usersandroles/rolesoverview/customerrolestab': 'Administrative Access',
    '/user/details/myroles': 'My Roles',
    '/user/details/mymobiletokens': 'My Mobile Tokens',
    '/user/details/mylinkedusers': 'My Linked Users',
    '/user/details/mytwofactorauthentication': 'My two factor authentication',
    '/distributor/support/companymove': 'Company Move',
    '/customer/usersaccess/overview/serviceaccesstab': 'Service access',
    '/customer/usersaccess/overview/rolesmanagement': 'Role management',
    '/customer/usersaccess/changeserviceaccess/rightside/pendingchanges': 'Change service access > Pending changes',
    '/customer/usersaccess/changeserviceaccess/rightside/newuser': 'Change service access > New user',
    '/customer/usersaccess/changeserviceaccess/rightside/edituser': 'Change service access > Edit user',
    '/customer/usersaccess/overview/administrativeaccesstab': 'Administrative Access',
    '/customer/usersaccess/changeadministrativeaccess/rightside/pendingchanges': 'Change administrative access > Pending changes',
    '/customer/usersaccess/changeadministrativeaccess/rightside/newuser': 'Change administrative access > New user',
    '/customer/usersaccess/changeadministrativeaccess/rightside/edituser': 'Change administrative access > Edit user',
    '/customer/reports/invoices/invoicestab': 'Reports > Invoices (datalake)',
    '/customer/reports/invoices/invoicedetailstab': 'Reports > Invoice details (datalake)',
    '/customer/reports/invoices/usagedetailstab': 'Reports > Invoice usage details (datalake)'
};
