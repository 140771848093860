import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, retry, throwError, timer } from "rxjs";
import { DomainService } from '../domain/domain.service';
import { WindowRef } from '../domain/window-ref';


@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    constructor(private domain: DomainService, private windowRef: WindowRef) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.domain.isUrlExcludedFromRetryStrategy(req.url)) {
            return next.handle(req);
        } else {
            const hostname = this.windowRef.getHostname();
            const baseUrl = this.domain.getBaseUrl(hostname);
            const clone = req.clone({
                url: `${baseUrl}${req.url}`
            });

            return next.handle(clone)
                .pipe(retry({ count: 1, delay: (err) => this.retryStrategy$(err) }));
        }
    }

    private retryStrategy$(error: HttpErrorResponse) {
        const shoudRetry = error.status !== 401 && error.status !== 403 && error.status !== 404 && error.status !== 405 && error.status !== 0;
        return shoudRetry ? timer(500) : throwError(() => error);
    }
}
