import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Report } from './report';
import { LazyLoadEvent } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { EXPORT_COOKIE } from 'src/app/const/export-cookie';
import { BehaviorSubject, Observable } from 'rxjs';
import { NotificationItem } from './notification-item';
import { Notifications } from './notifications';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  private _notification = new BehaviorSubject<NotificationItem>(null);
  public notification$: Observable<NotificationItem> = this._notification.asObservable();

  constructor(private http: HttpClient, private cookieService: CookieService) { }

  private getParams(event: LazyLoadEvent): { [key: string]: string } {
    let params = {};
    if (event && event.filters) {
      const keys = Object.keys(event.filters);
      let key: string;
      for (let i = 0, len = keys.length; i < len; i++) {
        key = keys[i];
        params[key] = event.filters[key].value;
      }
    }
    return params;
  }

  send(ntf: NotificationItem): void {
    this._notification.next(ntf);
  }

  sendRequestForEffectiveRoleExport(type: string, estimatedCount: number, event?: LazyLoadEvent): Observable<Report> {
    const report = new Report(type, estimatedCount, this.getParams(event));
    const url = '/webapi/rest/export/sendRequest';
    return this.http.post<Report>(url, report);
  }

  sendRequestForInvoiceExport(idInvoice: string, estimatedCount: number): Observable<any> {
    const report = {
      invoiceId: idInvoice,
      estimatedCount: estimatedCount
    }
    const url = '/webapi/rest/export/invoice/sendRequest';
    return this.http.post<any>(url, report);
  }

  getNotifications(): Observable<Notifications> {
    const url = '/webapi/rest/notifications';
    return this.http.get<Notifications>(url);
  }

  private getExpiresDate(minutes: number): Date {
    const expires = new Date().getTime() + minutes * 60000;
    return new Date(expires);
  }

  isExportEnabled(name: string, totalNoRecords: number): boolean {
    if (totalNoRecords === 0 || this.cookieService.get(name)) {
      return false;
    }
    return true;
  }

  setExportCookie(name: string): void {
    const expires = this.getExpiresDate(EXPORT_COOKIE.minutesToExpire);
    this.cookieService.set(name, EXPORT_COOKIE.value, expires, '/', '', false, 'Strict');
  }

  deleteExportCookie(name: string): void {
    this.cookieService.delete(name, '/');
  }
}
