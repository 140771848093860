import { Injectable } from '@angular/core';
import { GRID } from 'src/app/const/grid';

import { HttpParams } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api/lazyloadevent';
import { GridParam } from './grid-param';
import { CustomHttpParamEncoder } from '../http-interceptors/custom-http-param-encoder';
import { SORT } from 'src/app/const/sort';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  getParams(event: LazyLoadEvent, arr?: GridParam[]): HttpParams {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    let i: number;
    let len: number;
    let key: string;

    if (arr && arr.length > 0) {
      for (i = 0, len = arr.length; i < len; i++) {
        params = params.append(arr[i].key, arr[i].value);
      }
    }
    params = params.append(GRID.param.start, event.first);
    params = params.append(GRID.param.limit, event.rows);
    if (event.sortField) {
      params = params.append(GRID.param.sortField, event.sortField)
        .append(GRID.param.sortOrder, event.sortOrder);
    }
    const keys = Object.keys(event.filters);
    for (i = 0, len = keys.length; i < len; i++) {
      key = keys[i];
      params = params.append(key, event.filters[key].value);
    }
    return params;
  }

  getParamsForSpring(event: LazyLoadEvent, arr?: GridParam[]): HttpParams {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    let i: number;
    let len: number;

    if (arr && arr.length > 0) {
      for (i = 0, len = arr.length; i < len; i++) {
        params = params.append(arr[i].key, this.getQueryParamValue(arr[i].value, arr[i].operator));
      }
    }
    const page = event.first / event.rows;
    params = params.append(GRID.param.page, page);
    params = params.append(GRID.param.size, event.rows);
    if (event.sortField) {
      const order = this.getSortParamValue(event.sortField, event.sortOrder);
      params = params.append(GRID.param.sort, order);
    }
    const value = this.getFilterParamValue(event.filters);
    if (value.length > 0) {
      params = params.append(GRID.param.filter, value);
    }
    return params;
  }

  private getQueryParamValue(value: string | number | boolean, operator: string) {
    if (operator) {
      return `${operator}|${value}`;
    }
    return value;
  }

  private getFilterParamValue(filters: any): string {
    let key: string;
    let value = '';
    const keys = Object.keys(filters);
    for (let i = 0, len = keys.length; i < len; i++) {
      key = keys[i];
      value += `${key}==*${filters[key].value}*`;
      if (i < len - 1) {
        value += ';';
      }
    }
    return value;
  }

  private getSortParamValue(sortField: string, sortOrder: number): string {
    let order = SORT.asc;
    if (sortOrder === -1) {
      order = SORT.desc;
    }
    return `${sortField},${order}`;
  }


}
