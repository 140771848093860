<div id="page">
  <admin-navbar id="nc3navigation"></admin-navbar>
  <div class="container-fluid no-padding">
    <admin-flash-message></admin-flash-message>
    <admin-error></admin-error>
    <div class="admin-container" [ngClass]="clazz">
      <div class="content-main">
        <router-outlet></router-outlet>

        @if (shown){
        <div role="alert" class="alert toast-alert toast-main alert-info alert-sm alert-dismissible fade in">
          <button type="button" class="close" (click)="closeNotification()">
            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
          </button>
          <a href="#" rel="noopener noreferrer" (click)="onClickNotification($event)">
            <div>
              <span>{{notificationTitle}}</span>
            </div>
          </a>
        </div>
        }
      </div>
    </div>


  </div>
  <div id="spinner" loading-widget class="admin-loading-widget">
    <img alt="" src="./assets/images/nordic-cool/spinners/spinner_64x64_light.gif" />
  </div>
  <footer>
    <img src="./assets/images/nordic-cool/visma-logo.svg" alt="" width="100" height="21">
  </footer>
</div>
