import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { finalize, } from 'rxjs/operators';
import { LoadingWidgetService } from '../directives/loading-widget/loading-widget.service';
import { Observable } from 'rxjs';
import { environment } from "src/environments/environment";
import { DomainService } from "../domain/domain.service";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

    constructor(private widget: LoadingWidgetService, private domain: DomainService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.domain.hasUrl(req.url, environment.skipLoadingInterceptor)) {
            return next.handle(req);
        } else {
            this.widget.incrementCount();
            return next.handle(req)
                .pipe(
                    finalize(() => {
                        this.widget.decrementCount();
                    })
                );
        }
    }
}
