export const DEFAULT_TIMEOUT = 4000;

export class FlashMessage {
    type: string;
    static readonly TYPE = {
        info: 'info',
        warning: 'warning'
    };
    text: string;
    timeout: number;

    constructor(type: string, text: string, timeout?: number) {
        this.type = type;
        this.text = text;
        if (!timeout) {
            timeout = DEFAULT_TIMEOUT;
        }
        this.timeout = timeout;
    }
}
