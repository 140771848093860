import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PATH } from './const/path';
import { LayoutDataService } from './layout-data.service';
import { NotificationItem } from './core/export/notification-item';
import { WindowRef } from './core/domain/window-ref';
import { ProfileService } from './core/auth/profile.service';
import { ExportService } from './core/export/export.service';

@Component({
  selector: 'admin-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, OnDestroy {

  clazz: string;
  ntf!: NotificationItem | null;
  notificationTitle = '';
  shown = false;
  message: { [key: string]: string } = {};
  private timeout: any;
  private subscription$: Subscription = new Subscription();
  private notification$: Subscription = new Subscription();

  constructor(private layoutDataService: LayoutDataService, private pfs: ProfileService,
    private windowRef: WindowRef, private exportService: ExportService) { }

  ngOnInit(): void {
    this.message = this.pfs.messages['notifications'];
    this.subscription$ = this.layoutDataService.path$
      .subscribe(value => {
        if (value) {
          if (value.indexOf(PATH.user) > -1) {
            this.clazz = 'user-context';
          } else if (value.indexOf(PATH.customer_usersaccess_overview) > -1) {
            this.clazz = 'bg-white';
          } else {
            this.clazz = null;
          }
        }
      });

    if (this.pfs.profile.type === 'CustomerOfVisma') {
      this.handleNotifications();
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
    this.notification$.unsubscribe();
  }

  onClickNotification(event: Event): void {
    event.preventDefault();
    if (this.ntf !== null) {
      this.windowRef.open(this.ntf.url, '_blank');
    }
  }

  closeNotification() {
    this.hideNotification();
  }

  private handleNotifications(): void {
    this.notification$ = this.exportService.notification$
      .subscribe(ntf => {
        this.ntf = ntf;
        if (this.ntf) {
          this.notificationTitle = this.pfs.getFormattedMessage(this.message['infowYourExportIsReady'],
            [this.message[this.ntf.exportType], this.ntf.customerName]);
          this.shown = true;
          this.timeout = setTimeout(() => {
            this.hideNotification();
          }, 30000);
        }
      });
  }

  private hideNotification(): void {
    this.shown = false;
    this.ntf = null;
    clearTimeout(this.timeout);
  }

}
