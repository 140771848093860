import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutDataService {

  private _path = new BehaviorSubject<string>(null);
  public path$: Observable<string> = this._path.asObservable();

  constructor() { }

  sendPath(path: string): void {
    this._path.next(path);
  }
}
