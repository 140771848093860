import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { HttpErrorService } from './http-error.service';
import { ErrorNotification } from './error-notification';
import { ProfileService } from '../auth/profile.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'admin-error',
  templateUrl: './http-error.component.html'
})
export class HttpErrorComponent implements OnInit, OnDestroy {
  private translationIndex = 'error';
  status: number;
  message: string;
  errorCode: string;
  guid: string;
  
  private subscription$: Subscription = new Subscription();
  private subscriptionReset$: Subscription = new Subscription();

  constructor(private errntf: HttpErrorService, private ref: ChangeDetectorRef, private pfs: ProfileService) { }

  private getMessageForBaseError(translation: any, error: HttpErrorResponse): string {
    const message = translation[error.status];
    let msg = error.statusText;
    if (message) {
      msg = message;
    }
    return msg;
  }

  private getMessageForCustomError(translation: any, error: HttpErrorResponse): string {
    const err = error.error;
    let msg = translation[err.odpErrorCode + '_' + err.odpExceptionName];
    if (msg) {
      if (err.params) {
        const keys = Object.keys(err.params);
        const value = err.params[keys[0]];
        if (Array.isArray(value)) {
          const str = value.join(', ');
          msg = this.pfs.getFormattedMessage(msg, [str]);
        } else {
          msg = this.pfs.getFormattedMessage(msg, [value]);
        }
      }
    } else {
      msg = err.detailedMessage;
    }
    return msg;
  }

  private reset(): void {
    this.status = 0;
    this.ref.detectChanges();
  }

  ngOnInit() {
    const translation = this.pfs.messages[this.translationIndex];
    this.ref.detach();
    this.subscription$ = this.errntf.notification$
      .subscribe(ntf => {
        if (ntf && ntf.type === ErrorNotification.TYPE.http) {
          this.guid = ntf.error.headers.get('x-correlation-id');
          this.status = ntf.error.status;
          this.errorCode = translation['errorCode'];
          if (ntf.error.error && ntf.error.error.odpErrorCode) {
            this.message = this.getMessageForCustomError(translation, ntf.error);
          } else {
            this.message = this.getMessageForBaseError(translation, ntf.error);
          }
        }
        this.ref.detectChanges();
      });

    this.subscriptionReset$ = this.errntf.reset$
      .subscribe(value => {
        if (value) {
          this.reset();
        }
      });
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
    this.subscriptionReset$.unsubscribe();
  }

  close() {
    this.reset();
  }

}
