export const DATE_FORMAT = {
    en: "dd/MM/yyyy",
    no: "dd.MM.yyyy",
    nb: "dd.MM.yyyy",
    sv: "yyyy-MM-dd",
    fi: "d.M.yyyy",
    nl: "d-M-yy",
    da: "dd/MM/yy",
    ro: "dd.MM.yyyy"
}
