export const environment = {
  production: true,
  defaultLanguage: 'en',
  supportedLanguages: ['da', 'en', 'fi', 'nb', 'nl', 'no', 'sv'],
  excludedUrlsFromRetryStrategy: ['/assets/i18n/'],
  api: {
    int: 'https://api.adm2.aws.int.test.visma.net',
    sit: 'https://api.adm2.sit.test.visma.net',
    stag: 'https://api.adm2.stag.visma.net',
    prod: 'https://api.adm2.visma.net'
  },
  walkMe: {
    int: {
      scriptUrl: 'https://eu-cdn.walkme.com/users/156f0c8fa19a4b7cbd7437d888ce43cc/test/walkme_private_156f0c8fa19a4b7cbd7437d888ce43cc_https.js',
      hashcode: 'sha256-xA7YXeCjh992RrqQgJYVJkaC1WKs0iPQ4lE/tNN65b4='
    },
    sit: {
      scriptUrl: 'https://eu-cdn.walkme.com/users/156f0c8fa19a4b7cbd7437d888ce43cc/test/walkme_private_156f0c8fa19a4b7cbd7437d888ce43cc_https.js',
      hashcode: 'sha256-xA7YXeCjh992RrqQgJYVJkaC1WKs0iPQ4lE/tNN65b4='
    },
    stag: {
      scriptUrl: 'https://eu-cdn.walkme.com/users/156f0c8fa19a4b7cbd7437d888ce43cc/test/walkme_private_156f0c8fa19a4b7cbd7437d888ce43cc_https.js',
      hashcode: 'sha256-xA7YXeCjh992RrqQgJYVJkaC1WKs0iPQ4lE/tNN65b4='
    },
    prod: {
      scriptUrl: 'https://eu-cdn.walkme.com/users/156f0c8fa19a4b7cbd7437d888ce43cc/walkme_private_156f0c8fa19a4b7cbd7437d888ce43cc_https.js',
      hashcode: 'sha256-bLZ0I5NX1aRtz99HlrpFoeWVRLOlAerodXrCyWe5RvA='
    }
  },
  mock: {
    enabled: true,
    requests: [],
    pathParamsShouldNotBeSentFor: ['userByEmail'],
    api: {
      int: 'https://api-mock.adm2.aws.int.test.visma.net',
      sit: 'https://api-mock.adm2.sit.test.visma.net',
      stag: 'https://api-mock.adm2.stag.visma.net',
      prod: 'https://api-mock.adm2.visma.net'
    }
  },
  gridPaths: [],
  skipLoadingInterceptor: ['/webapi/rest/user/queuedOperation', '/webapi/rest/notifications']
};
