export class Report {
    type: string;
    estimatedCount: number;
    params: { [key: string]: string };

    constructor(type: string, estimatedCount: number, params: { [key: string]: string }){
        this.type = type;
        this.estimatedCount = estimatedCount;
        this.params = params;
    }
}
