import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ErrorNotification } from './error-notification';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  private _notification = new BehaviorSubject<ErrorNotification>(null);
  public notification$: Observable<ErrorNotification> = this._notification.asObservable();

  private _reset = new BehaviorSubject<boolean>(null);
  public reset$: Observable<boolean> = this._reset.asObservable();

  constructor() { }

  send(type, error): void {
    let ntf = new ErrorNotification(type, error);
    this._notification.next(ntf);
  }

  reset() {
    this._reset.next(true);
  }
}
