import { Component, OnInit } from '@angular/core';
import { NavbarService } from '../navbar/navbar.service';
import { ProfileService } from '../auth/profile.service';
import { SESSION_STORAGE_KEY } from 'src/app/const/session-storage-key';

@Component({
  templateUrl: './page-not-found.component.html',
})
export class PageNotFoundComponent implements OnInit {

  private translationIndex = 'pageNotFound';
  message: any;
  goHomeUrl: string;

  constructor(private pfs: ProfileService, private navbar: NavbarService) { }

  ngOnInit() {
    sessionStorage.removeItem(SESSION_STORAGE_KEY.adminUIPath);
    this.message = this.pfs.messages[this.translationIndex];
    this.goHomeUrl = this.navbar.getProfileDefaultPath(this.pfs.profile);
  }

}
