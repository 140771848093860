@if(status){
<div>
    <div class="admin-alert alert alert-sm alert-danger" role="alert">
        <button type="button" class="close" (click)="close()">
            <span aria-hidden="true">&times;</span><span class="sr-only">Close</span>
        </button>
        <div>
            <span class="vismaicon vismaicon-filled vismaicon-error" aria-hidden="true"></span>
            <span class="sr-only">Error:</span>
            <p>{{message}} @if(guid) {<span>| {{errorCode}}: {{guid}}</span>}</p>
        </div>
    </div>
</div>
}
