import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../auth/profile.service';
import { SESSION_STORAGE_KEY } from 'src/app/const/session-storage-key';


@Component({
  templateUrl: './no-connection.component.html'
})
export class NoConnectionComponent implements OnInit {
  private translationIndex = 'noConnection';
  message: any;

  constructor(private pfs: ProfileService) { }

  ngOnInit() {
    sessionStorage.removeItem(SESSION_STORAGE_KEY.adminUIPath);
    this.message = this.pfs.messages[this.translationIndex];
  }

}
