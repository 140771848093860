import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PAGE_TITLE } from 'src/app/const/page-title';
import { Hashcode } from './hashcode';

@Injectable({
  providedIn: 'root'
})
export class DomService {

  constructor(private http: HttpClient) { }

  getHashcode(hashcodeUrl: string): Observable<Hashcode> {
    return this.http.get<Hashcode>(hashcodeUrl);
  }

  createScriptTag(url: string, callback?: any): void {
    const body = document.body;
    let script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', url);
    script.async = true;
    if (callback) {
      script.onload = (event) => {
        callback();
      }
    }
    body.appendChild(script);
  }

  createScriptTagWithHashCode(scriptUrl: string, value: string, timestamp: number, callback?: any): void {
    const integrity = value;
    const body = document.body;
    let script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', scriptUrl + '?' + timestamp);
    script.setAttribute('integrity', integrity);
    script.setAttribute('crossorigin', 'anonymous');
    script.async = true;
    if (callback) {
      script.onload = (event) => {
        callback();
      }
    }
    body.appendChild(script);
  }

  private getTitle(path: string): string {
    let title = 'Not found';
    const keys = Object.keys(PAGE_TITLE);
    for (let i = 0, len = keys.length; i < len; i++) {
      const key = keys[i];
      if (path.indexOf(key) > -1) {
        title = PAGE_TITLE[key];
        break;
      }
    }
    return title;
  }

  setPageTitle(path): void {
    const title = this.getTitle(path);
    const tag = document.getElementsByTagName("title")[0];
    let text = tag.textContent;
    const index = text.indexOf(' >');
    if (index > -1) {
      text = text.substring(0, index);
    }
    tag.textContent = text + ' > ' + title;
  }

  hideNoProfileSpinner(): void {
    let el = document.getElementById('noProfileSpinner');
    if (el) {
      el.style.display = 'none';
    }
  }

  setLangAttribute(language: string): void {
    if (language === 'no') {
      language = 'nb';
    }
    document.documentElement.setAttribute('lang', language);
  }

}
