import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { HttpErrorService } from '../http-error/http-error.service';
import { ErrorNotification } from '../http-error/error-notification';
import { DomainService } from '../domain/domain.service';
import { AuthService } from '../auth/auth.service';
import { WindowRef } from '../domain/window-ref';
import { PATH } from 'src/app/const/path';
import { NavbarService } from '../navbar/navbar.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(private auth: AuthService, private router: Router, private errntf: HttpErrorService, 
    private service: NavbarService,
    private domain: DomainService, private windowRef: WindowRef) { }

  handleError(error: any) {

    if (error instanceof HttpErrorResponse) {
      if (!this.windowRef.isOnline()) {
        this.router.navigateByUrl(PATH.no_connection);
      } else {
        //backend error
        if (error.status === 401 || error.status === 403) {
          this.auth.getLoginUrl().subscribe(response => {
            this.service.removeProfileItems();
            this.windowRef.setLocationHref(response.url);
          });
        } else if(error.status === 0){
          console.error(error);
        } else {
          if (!this.domain.isUrlExcludedFromRetryStrategy(error.url)) {
            this.errntf.send(ErrorNotification.TYPE.http, error);
          }
        }
      }
    } else {
      //A client-side or network error occurred.
      if (error) {
        console.error(error);
      }
    }

  }
}
