import { Injectable } from '@angular/core';
import { VISMA_MENU } from '../../const/visma-menu';
import { Profile } from '../auth/profile';
import nc3navigation from 'src/assets/nc3-navigation-native/nc3navigation';
import { NavItem } from './nav-item';
import { PATH } from 'src/app/const/path';
import { CONTEXT_TYPE } from 'src/app/const/context-type';
import { DISTRIBUTOR_MENU } from 'src/app/const/distributor-menu';
import { USER_MENU } from 'src/app/const/user-menu';
import { HOSTNAME } from 'src/app/const/hostname';
import { OLD_ADMIN_HOSTNAME } from 'src/app/const/old-admin-hostname';
import { CUSTOMER_MENU } from 'src/app/const/customer-menu';
import { SESSION_STORAGE_KEY } from 'src/app/const/session-storage-key';
import { ENV } from 'src/app/const/env';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor() { }

  private getUrlParameter(name: string, search: string): string {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  }

  getContextId(search: string) {
    let id = this.getUrlParameter('contextSelector:switchContext', search);
    if (!id) {
      id = sessionStorage.getItem(SESSION_STORAGE_KEY.adminUIContextId);
    }
    if (id === 'null' || id === 'undefined') {
      id = null;
    }
    return id;
  }

  getContextType(pathname: string): string {
    let type: string;
    if (pathname.indexOf(PATH.user) > -1) {
      type = CONTEXT_TYPE.user;
    } else if (pathname.indexOf(PATH.customer) > -1) {
      type = CONTEXT_TYPE.customerOfVisma;
    } else if (pathname.indexOf(PATH.distributor) > -1) {
      type = CONTEXT_TYPE.distributor;
    } else if (pathname.indexOf(PATH.visma) > -1) {
      type = CONTEXT_TYPE.visma;
    }
    return type;
  }

  handleDirectLink(pathname): void {
    if (pathname !== '/' && pathname !== '' && pathname.indexOf('index.html') === -1 &&
      pathname.indexOf('debug.html') === -1 && pathname.indexOf('context.html') === -1) {
      const path = sessionStorage.getItem(SESSION_STORAGE_KEY.adminUIPath);
      if (path !== pathname) {
        sessionStorage.removeItem(SESSION_STORAGE_KEY.adminUIContextId);
      }
      sessionStorage.setItem(SESSION_STORAGE_KEY.adminUIPath, pathname);
    }
  }

  handleContextId(id: string): void {
    sessionStorage.setItem(SESSION_STORAGE_KEY.adminUIContextId, id);
  }

  setProfileItems(id: string, url: string): void {
    sessionStorage.setItem(SESSION_STORAGE_KEY.adminUIContextId, id);
    sessionStorage.setItem(SESSION_STORAGE_KEY.adminUIPath, url);
  }

  removeProfileItems(): void {
    sessionStorage.removeItem(SESSION_STORAGE_KEY.adminUIContextId);
    sessionStorage.removeItem(SESSION_STORAGE_KEY.adminUIPath);
  }

  private getNavItem(menu, path, defaultPath) {
    return {
      menu: menu,
      path: path || defaultPath
    }
  }

  private getOldAdminHostname(hostname: string): string {
    if (hostname === HOSTNAME.localhost) {
      return OLD_ADMIN_HOSTNAME.localhost;
    } else if (hostname === HOSTNAME.sit) {
      return OLD_ADMIN_HOSTNAME.sit;
    } else if (hostname === HOSTNAME.int) {
      return OLD_ADMIN_HOSTNAME.int;
    } else if (hostname === HOSTNAME.stag) {
      return OLD_ADMIN_HOSTNAME.stag;
    } else if (hostname === HOSTNAME.prod) {
      return OLD_ADMIN_HOSTNAME.prod;
    }
  }

  private setHref(menuItems: any[], hostname, id): any[] {
    for (let i = 0, len = menuItems.length; i < len; i++) {
      if (menuItems[i].href) {
        if (menuItems[i].href.indexOf('https://') === -1) {
          menuItems[i].href = 'https://' + hostname + menuItems[i].href + '?contextSelector:switchContext=' + id;
        } else if (menuItems[i].href.indexOf('?contextSelector:switchContext=') > -1) {
          menuItems[i].href = menuItems[i].href.replace(/=\d+/, '=' + id);
        }
      }
    }
    return menuItems;
  }

  private indexOf(array: nc3navigation.MenuItem[], item: { id: string, value: boolean }): number {
    let index = -1;
    if (array && array.length) {
      for (let i = 0, len = array.length; i < len; i++) {
        if (item.id === array[i].id) {
          index = i;
          break;
        }
      }
    }
    return index;
  }

  setMenuItemsDisplayStatus(menu: nc3navigation.MenuItem[], args: { id: string, value: boolean }[]): any[] {
    if (args && args.length) {
      for (let i = 0, len = args.length; i < len; i++) {
        let index = this.indexOf(menu, args[i]);
        if (index !== -1) {
          menu[index].shown = args[i].value;
        }
      }
    }
    return menu;
  }

  getRedirectLink(hostname: string, item: nc3navigation.ContextItem) {
    const h = this.getOldAdminHostname(hostname);
    let link = `https://${h}/admin`;
    if (item.type === CONTEXT_TYPE.customerOfVisma) {
      link += '/startpage/';
    } else if (item.type === CONTEXT_TYPE.visma) {
      link += '/supportvisma/supportuser/userstab/';
    } else if (item.type === CONTEXT_TYPE.distributor) {
      link += '/supportdistributor/partnersupportcontacts/partnersupportcontactstab/';
    }
    link += `?contextSelector:switchContext=${item.id}`;
    return link;
  }

  getUserDetailsLink(hostname: string, idCustomer: string | number, idUser: number) {
    const h = this.getOldAdminHostname(hostname);
    let link = `https://${h}/admin/usersandroles/users/userdetailstab/${idUser}?contextSelector:switchContext=${idCustomer}`;
    return link;
  }

  getSelectedAppPrefix(type: string) {
    let prefix = 'Visma.net';
    if (type === CONTEXT_TYPE.user) {
      prefix = ''
    }
    return prefix;
  }

  getProfileDefaultPath(profile: Profile): string {
    let path;
    if (profile.type === CONTEXT_TYPE.visma) {
      path = PATH.visma_support_companies;
    } else if (profile.type === CONTEXT_TYPE.distributor) {
      path = PATH.distributor_support;
    } else if (profile.type === CONTEXT_TYPE.customerOfVisma) {
      path = PATH.customer_usersandroles_rolesoverview_companyrolestab;
    } else if (profile.type === CONTEXT_TYPE.user) {
      path = PATH.user_details_myroles;
    }
    return path;
  }

  getProfileNavItem(profile: Profile, hostname: string, resetProfileItems?: boolean): NavItem {
    let path, item, menu, args;
    if (!resetProfileItems) {
      path = sessionStorage.getItem(SESSION_STORAGE_KEY.adminUIPath);
    }

    if (profile.type === CONTEXT_TYPE.visma) {
      item = this.getNavItem(VISMA_MENU, path, PATH.visma_support_companies);
    } else if (profile.type === CONTEXT_TYPE.distributor) {
      hostname = this.getOldAdminHostname(hostname);

      menu = DISTRIBUTOR_MENU;
      menu.firstLeveLMenu = this.setHref(menu.firstLeveLMenu, hostname, profile.id);

      args = [{ id: 'companyMove', value: profile.companyMoveEnabled }];
      menu.secondLevelMenu = this.setHref(menu.secondLevelMenu, hostname, profile.id);
      menu.secondLevelMenu = this.setMenuItemsDisplayStatus(menu.secondLevelMenu, args);

      item = this.getNavItem(DISTRIBUTOR_MENU, path, PATH.distributor_support_companymove)

    } else if (profile.type === CONTEXT_TYPE.customerOfVisma) {
      hostname = this.getOldAdminHostname(hostname);
      menu = CUSTOMER_MENU;

      menu.firstLeveLMenu = this.setHref(menu.firstLeveLMenu, hostname, profile.id);

      args = [{ id: 'userGroups', value: profile.userGroupsEnabled }];
      menu.secondLevelMenu = this.setHref(menu.secondLevelMenu, hostname, profile.id);
      menu.secondLevelMenu = this.setMenuItemsDisplayStatus(menu.secondLevelMenu, args);

      item = this.getNavItem(menu, path, PATH.customer_usersandroles_rolesoverview_companyrolestab);

    } else if (profile.type === CONTEXT_TYPE.user) {
      item = this.getNavItem(USER_MENU, path, PATH.user_details_myroles);
    }
    return item;
  }

  getEnviroment(hostname: string): string {
    if (hostname === HOSTNAME.localhost) {
      return ENV.localhost;
    } else if (hostname === HOSTNAME.sit) {
      return ENV.sit;
    } else if (hostname === HOSTNAME.int) {
      return ENV.int;
    } else if (hostname === HOSTNAME.stag) {
      return ENV.stag;
    } else if (hostname === HOSTNAME.prod) {
      return ENV.prod;
    }
  }

  getCulture(language: string): string {
    let culture = 'en-US';
    if (language === 'da') {
      culture = 'da-DK';
    } else if (language === 'fi') {
      culture = 'fi-FI';
    } else if (language === 'nb') {
      culture = 'nb-NO';
    } else if (language === 'no') {
      culture = 'no-NO';
    } else if (language === 'nl') {
      culture = 'nl-NL';
    } else if (language === 'sv') {
      culture = 'sv-SE';
    }
    return culture;
  }

  isWalkMeEnabled(enabled: boolean, contextType: string, language: string): boolean {
    return enabled && contextType === CONTEXT_TYPE.customerOfVisma && (language === 'no' || language === 'en');
  }

}
