import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layout.component';
import { NoConnectionComponent } from './core/error-page/no-connection.component';
import { PageNotFoundComponent } from './core/error-page/page-not-found.component';
import { ForbiddenComponent } from './core/error-page/forbidden.component';
import { ProfileGuard } from './core/guards/profile.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ProfileGuard],
    children: [
      {
        path: 'customer',
        loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: 'distributor',
        loadChildren: () => import('./distributor/distributor.module').then(m => m.DistributorModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'no-connection',
        component: NoConnectionComponent
      },
      {
        path: 'forbidden',
        component: ForbiddenComponent
      },
      { path: '**', component: PageNotFoundComponent }
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
