import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { FlashMessageService } from './flash-message.service';

@Component({
  selector: 'admin-flash-message',
  templateUrl: './flash-message.component.html',
})
export class FlashMessageComponent implements OnInit, OnDestroy {
  private subscription$: Subscription = new Subscription();
  alertClass: string;
  iconClass: string;
  text: string;
  alertPrefix = 'alert-';
  iconPrefix = 'vismaicon-'
  shown = false;
  private timeout: any;

  constructor(private service: FlashMessageService, private ref: ChangeDetectorRef) { }

  private hide() {
    this.shown = false;
    this.ref.detectChanges();
    clearTimeout(this.timeout);
  }

  ngOnInit(): void {
    this.ref.detach();
    this.subscription$ = this.service.notification$
      .subscribe(ntf => {
        if (ntf) {
          clearTimeout(this.timeout);
          this.alertClass = this.alertPrefix + ntf.type;
          this.iconClass = this.iconPrefix + ntf.type;
          this.text = ntf.text;
          this.shown = true;

          this.timeout = setTimeout(() => {
            this.hide();
          }, ntf.timeout);
        }
        this.ref.detectChanges();

      });
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  close() {
    this.hide();
  }

}
