import { Injectable } from '@angular/core';
import { Profile } from './profile';
import { DATE_FORMAT } from 'src/app/const/date-format';
import { DATETIME_FORMAT } from 'src/app/const/datetime-format';


@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _profile: Profile;
  private _messages: { [key: string]: { [key: string]: string; } };

  constructor() { }

  get profile(): Profile {
    return this._profile;
  }

  set profile(profile: Profile) {
    this._profile = profile;
  }

  get messages(): { [key: string]: { [key: string]: string; } } {
    return this._messages;
  }

  set messages(messages: { [key: string]: { [key: string]: string; } }) {
    this._messages = messages;
  }

  getLanguage(languages: string[], def: string, language: string): string {
    let lang = def;
    for (let i = 0; i < languages.length; i++) {
      if (languages[i] === language) {
        lang = language;
        break;
      }
    }
    return lang;
  }

  getFormattedMessage(msg: string, values?: any[]): string {
    if (values && values.length > 0) {
      for (let i = 0, len = values.length; i < len; i++) {
        msg = msg.replace('{' + i + '}', values[i]);
      }
    }
    return msg;
  }

  hasProfile(): boolean {
    if (this._profile && this._profile.language && this._messages) {
      return true;
    }
    return false;
  }

  getHelpCenterRootUrl(url: string): string {
    if (url.lastIndexOf('/') < url.length - 1) {
      url += '/';
    }
    return url;
  }

  extend(obj1: any, obj2: any): any {
    let result: any = JSON.parse(JSON.stringify(obj1));
    const keys = Object.keys(obj2);
    for (let i = 0; i < keys.length; i++) {
      const val = obj2[keys[i]];
      if (typeof val === 'string') {
        result[keys[i]] = val;
      } else {
        result[keys[i]] = this.extend(result[keys[i]] || {}, val);
      }
    }
    return result;
  }

  formatDate(language, time) {
    return this.formatTime(time, DATE_FORMAT[language]);
  }

  formatDateTime(language, time) {
    return this.formatTime(time, DATETIME_FORMAT[language]);
  }

  private joinObj(obj, seperator) {
    let out = [];
    for (let k in obj) {
      out.push(k);
    }
    return out.join(seperator);
  }

  private formatTime(time, format) {
    const date = new Date(time);
    const dateObject = {
      M: date.getMonth() + 1,
      d: date.getDate(),
      D: date.getDate(),
      h: date.getHours(),
      H: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds(),
      y: date.getFullYear(),
      Y: date.getFullYear()
    };
    const dateMatchRegex = this.joinObj(dateObject, "+|") + "+";
    const regEx = new RegExp(dateMatchRegex, "g");

    return format.replace(regEx, function (formatToken) {
      const datePartValue = dateObject[formatToken.slice(-1)].toString();
      let tokenLength = formatToken.length;
      if (formatToken.indexOf('y') < 0 && formatToken.indexOf('Y') < 0) {
        tokenLength = Math.max(formatToken.length, datePartValue.length);
      }
      const zeroPad = (datePartValue.length < formatToken.length ? "0".repeat(tokenLength) : "");
      return (zeroPad + datePartValue).slice(-tokenLength);
    });

  }

}
