export class ErrorNotification {
    type: string;
    error: any;
    static readonly TYPE = {
        http: 'http',
        client: 'client'
    };

    constructor(type: string, error: any) {
        this.type = type;
        this.error = error;
    }
}
