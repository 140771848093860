export const GRID = {
    limit: 50,
    debounceTime: 500,
    param: {
        start: 'start',
        limit: 'limit',
        page: 'page',
        size: 'size',
        sort: 'sort',
        sortField: 'sortField',
        sortOrder: 'sortOrder',
        estimatedCount: 'estimatedCount',
        filter: 'filter'
    }
}
