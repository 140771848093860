import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FlashMessage } from './flash-message';

@Injectable({
  providedIn: 'root'
})
export class FlashMessageService {

  private _notification = new BehaviorSubject<FlashMessage>(null);
  public notification$: Observable<FlashMessage> = this._notification.asObservable();

  constructor() { }

  send(type, text, timeout?: number): void {
    let ntf = new FlashMessage(type, text, timeout);
    this._notification.next(ntf);
  }
}
