import { PATH } from './path';

export const VISMA_MENU = {
    firstLeveLMenu:
        [
            {
                id: "vismaSupport",
                text: 'menuItemVismaSupport',
                path: PATH.visma_support_companies,
                activeWhen: PATH.visma_support,
                shown: true
            }
        ],
    secondLevelMenu:
        [
            {
                id: "vismaSupportCompany",
                text: 'menuItemVismaSupportCompany',
                path: PATH.visma_support_companies,
                activeWhen: PATH.visma_support_companies,
                shown: true
            }
       ]
}