export const PATH = {
    admin: '/',
    no_connection: '/no-connection',
    not_found: '/notfound',
    forbidden: '/forbidden',
    visma: '/visma/',
    distributor: '/distributor/',
    customer: '/customer/',
    user: '/user/',
    visma_support: '/visma/support',
    visma_support_companies: '/visma/support/companies',
    distributor_support: '/distributor/support',
    distributor_support_companymove: '/distributor/support/companymove',
    customer_startpage: '/customer/startpage',
    customer_usersandroles: '/customer/usersandroles',
    customer_usersandroles_users: '/customer/usersandroles/users',
    customer_usersandroles_users_userstab: '/customer/usersandroles/users/userstab',
    customer_usersandroles_users_newusertab: '/customer/usersandroles/users/newusertab',
    customer_usersandroles_users_usertab: '/customer/usersandroles/users/usertab',
    customer_usersandroles_usergroups: '/customer/usersandroles/usergroups',
    customer_usersandroles_usergroups_usergroupstab: '/customer/usersandroles/usergroups/usergroupstab',
    customer_usersandroles_usergroups_newusergrouptab: '/customer/usersandroles/usergroups/newusergrouptab',
    customer_usersandroles_usergroups_usergroupdetailstab: '/customer/usersandroles/usergroups/usergroupdetailstab',
    customer_usersandroles_roles: '/customer/usersandroles/roles',
    customer_usersandroles_roles_rolestab: '/customer/usersandroles/roles/rolestab',
    customer_usersandroles_roles_rolestab_users: '/customer/usersandroles/roles/rolestab/users',
    customer_usersandroles_roles_rolestab_usergroups: '/customer/usersandroles/roles/rolestab/usergroups',
    customer_usersandroles_rolecenter: '/customer/usersandroles/rolecenter',
    customer_usersandroles_rolecenter_rolestab: '/customer/usersandroles/rolecenter/rolecentertab',
    customer_usersandroles_rolecenter_rolestab_users: '/customer/usersandroles/rolecenter/rolecentertab/users',
    customer_usersandroles_rolecenter_rolestab_usergroups: '/customer/usersandroles/rolecenter/rolecentertab/usergroups',
    customer_usersandroles_rolecenter_userdetailstab: '/customer/usersandroles/rolecenter/userdetailstab',
    customer_usersandroles_rolecenter_usergroupdetailstab: '/customer/usersandroles/rolecenter/usergroupdetailstab',
    customer_usersandroles_roles_userdetailstab: '/customer/usersandroles/roles/userdetailstab',
    customer_usersandroles_roles_newusertab: '/customer/usersandroles/roles/newusertab',
    customer_usersandroles_roles_usergroupdetailstab: '/customer/usersandroles/roles/usergroupdetailstab',
    customer_usersandroles_roles_newusergrouptab: '/customer/usersandroles/roles/newusergrouptab',
    customer_usersandroles_reports: '/customer/usersandroles/reports',
    customer_usersandroles_reports_reportstab: '/customer/usersandroles/reports/reportstab',
    customer_usersandroles_integratedusers: '/customer/usersandroles/integratedusers',
    customer_usersandroles_rolesoverview: '/customer/usersandroles/rolesoverview',
    customer_usersandroles_rolesoverview_companyrolestab: '/customer/usersandroles/rolesoverview/companyrolestab',
    customer_usersandroles_rolesoverview_customerrolestab: '/customer/usersandroles/rolesoverview/customerrolestab',
    customer_reports: '/customer/reports',
    customer_reports_loginhistory: '/customer/reports/loginhistory',
    customer_reports_invoices: '/customer/reports/invoices',
    customer_reports_invoices_invoicestab: '/customer/reports/invoices/invoicestab',
    customer_reports_invoices_invoicedetailstab: '/customer/reports/invoices/invoicedetailstab',
    customer_reports_invoices_usagedetailstab: '/customer/reports/invoices/usagedetailstab',
    customer_companies_companiescrud: '/customer/companies/companiescrud',
    customer_companies_companiescrud_companiestab: '/customer/companies/companiescrud/companiestab',
    customer_companies_companiescrud_newcompanytab: '/customer/companies/companiescrud/newcompanytab',
    customer_companies_companiescrud_companydetailstab: '/customer/companies/companiescrud/companydetailstab',
    customer_companies_companygroups: '/customer/companies/companygroups',
    customer_companies_companygroups_companygroupstab: '/customer/companies/companygroups/companygroupstab',
    customer_companies_companygroups_newcompanygrouptab: '/customer/companies/companygroups/newcompanygrouptab',
    customer_companies_companygroups_companygroupdetailstab: '/customer/companies/companygroups/companygroupdetailstab',
    customer_companies_integratedcompanies: '/customer/companies/integratedcompanies',
    customer_system_customerdata: '/customer/system/customerdata',
    customer_system_customerdata_customertab: '/customer/system/customer/customertab',
    customer_system_customerdata_customerlabelstab: '/customer/system/customer/customerlabelstab',
    customer_system_support: '/customer/system/support',
    customer_system_support_distributortab: '/customer/system/support/distributortab',
    customer_system_support_partnertab: '/customer/system/support/partnertab',
    customer_system_emailtemplates: '/customer/system/emailtemplates',
    customer_system_customer_emergencycodestab: '/customer/system/customer/emergencycodestab',
    customer_system_certificates: '/customer/system/certificates',
    customer_system_certificates_certificatestab: '/customer/system/certificates/certificatestab',
    customer_usersaccess: '/customer/usersaccess',
    customer_usersaccess_overview: '/customer/usersaccess/overview',
    customer_usersaccess_overviews_rolesmanagement: '/customer/usersaccess/overview/serviceaccesstab',

    customer_usersaccess_changeaccess: '/customer/usersaccess/changeaccess',
    customer_usersaccess_changeaccess_changeserviceaccess_rightside_pendingchanges: '/customer/usersaccess/changeaccess/changeserviceaccess/rightside/pendingchanges',
    customer_usersaccess_changeaccess_changeserviceaccess_rightside_newuser: '/customer/usersaccess/changeaccess/changeserviceaccess/rightside/newuser',
    customer_usersaccess_changeaccess_changeserviceaccess_rightside_edituser: '/customer/usersaccess/changeaccess/changeserviceaccess/rightside/edituser',
    customer_usersaccess_changeaccess_changeadministrativeaccess_rightside_pendingchanges: '/customer/usersaccess/changeaccess/changeadministrativeaccess/rightside/pendingchanges',
    customer_usersaccess_changeaccess_changeadministrativeaccess_rightside_newuser: '/customer/usersaccess/changeaccess/changeadministrativeaccess/rightside/newuser',
    customer_usersaccess_changeaccess_changeadministrativeaccess_rightside_edituser: '/customer/usersaccess/changeaccess/changeadministrativeaccess/rightside/edituser',
    user_details: '/user/details',
    user_details_myroles: '/user/details/myroles',
    user_details_mymobiletokens: '/user/details/mymobiletokens',
    user_details_mylinkedusers: '/user/details/mylinkedusers'
}
