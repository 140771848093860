import { PATH } from './path';

export const CUSTOMER_MENU = {
    firstLeveLMenu:
        [
            {
                id: 'startPage',
                text: 'menuItemStartPage',
                href: '/admin/startpage/',
                target: '_self',
                shown: true
            },
            {
                id: 'usersAndRoles',
                text: 'menuItemUsersAndRoles',
                path: PATH.customer_usersandroles_rolesoverview_companyrolestab,
                activeWhen: PATH.customer_usersandroles,
                shown: true
            },
            {
                id: 'companies',
                text: 'menuItemCompanies',
                href: '/admin/companies/companiescrud/companiestab/',
                target: '_self',
                shown: true
            },
            {
                id: 'invoices',
                text: 'menuItemInvoices',
                path: PATH.customer_reports_invoices_invoicestab,
                activeWhen: PATH.customer_reports,
                shown: true
            },
            {
                id: 'supportOnCustomer',
                text: 'menuItemSupportCustomer',
                href: '/admin/supportoncustomer/support/distributortab/',
                target: '_self',
                shown: true
            },
            {
                id: 'system',
                text: 'menuItemSystem',
                href: '/admin/system/customer/customertab/',
                target: '_self',
                shown: true
            }
        ],
    secondLevelMenu: [
        {
            id: 'changeAccess',
            text: 'menuItemChangeAccess',
            path: PATH.customer_usersaccess_changeaccess_changeserviceaccess_rightside_pendingchanges,
            activeWhen: PATH.customer_usersaccess_changeaccess,
            shown: false
        },
        {
            id: 'users',
            text: 'menuItemUsers',
            href: '/admin/usersandroles/users/userstab/',
            parentId: 'usersAndRoles',
            target: '_self',
            shown: true
        },
        {
            id: 'roles',
            text: 'menuItemRoles',
            href: '/admin/usersandroles/roles/rolestab/users/',
            parentId: 'usersAndRoles',
            target: '_self',
            shown: true
        },
        {
            id: 'userGroups',
            text: 'menuItemUserGroups',
            href: '/admin/usersandroles/usergroups/usergroupstab/',
            parentId: 'usersAndRoles',
            target: '_self',
            shown: true
        },
        {
            id: 'roleGroupsConfig',
            text: 'menuItemRoleGroupsConfiguration',
            href: '/admin/usersandroles/rolegroupsconfig/rolegroupstab/',
            parentId: 'usersAndRoles',
            target: '_self',
            shown: true
        },
        {
            id: 'reportsRolesOverview',
            text: 'menuItemRolesAssignmentsOverview',
            path: PATH.customer_usersandroles_rolesoverview_companyrolestab,
            activeWhen: PATH.customer_usersandroles_rolesoverview,
            shown: true
        }
    ]
}
