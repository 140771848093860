import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { LayoutComponent } from './layout.component';
import { CoreModule } from './core/core.module';
import { CookieService } from 'ngx-cookie-service';
import { httpInterceptorProviders } from './core/http-interceptors';

@NgModule({ declarations: [
        AppComponent,
        LayoutComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule], providers: [
        httpInterceptorProviders,
        CookieService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
