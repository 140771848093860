import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from 'ngx-cookie-service';
import { XSRF_TOKEN } from 'src/app/const/xsrf-token';
import { ProfileService } from '../auth/profile.service';


@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
    constructor(private pfs: ProfileService, private cookieService: CookieService) { }

    private getContextId(url: string, params: HttpParams): string {
        let contextId;
        if (url.indexOf('/webapi/rest/profile') > -1) {
            if (params.get('id')) {
                contextId = params.get('id');
            }
        }
        return contextId;
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = new HttpHeaders({ 'Cache-Control': 'no-cache', 'Expires': '0', 'Content-Type': 'application/json' });

        const token = this.cookieService.get(XSRF_TOKEN.cookieName);
        if (token) {
            headers = headers.append(XSRF_TOKEN.headerName, token);
        }

        let contextId;
        if (this.pfs.profile && this.pfs.profile.id) {
            contextId = String(this.pfs.profile.id);
        } else {
            contextId = this.getContextId(req.url, req.params);
        }
        if (contextId) {
            headers = headers.append('Visma-ContextId', contextId);
        }
        const clone = req.clone({
            headers: headers,
            withCredentials: true
        });

        return next.handle(clone);
    }
}
