import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HOSTNAME } from 'src/app/const/hostname';
import { ENV } from 'src/app/const/env';


@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor() { }

  private getEnviroment(hostname: string): string {
    if (hostname === HOSTNAME.localhost) {
      return ENV.localhost;
    } else if (hostname === HOSTNAME.sit) {
      return ENV.sit;
    } else if (hostname === HOSTNAME.int) {
      return ENV.int;
    } else if (hostname === HOSTNAME.stag) {
      return ENV.stag;
    } else if (hostname === HOSTNAME.prod) {
      return ENV.prod;
    }
  }

  getBaseUrl(hostname: string): string {
    const env = this.getEnviroment(hostname);
    return environment.api[env];
  }

  isLocalhost(hostname: string): boolean {
    return this.isHostname(hostname, HOSTNAME.localhost);
  }

  isProduction(hostname: string): boolean {
    return this.isHostname(hostname, HOSTNAME.prod);
  }

  private isHostname(hostname: string, name: string) {
    let is = false;
    if (hostname === name) {
      is = true;
    }
    return is;
  }

  hasUrl(str: string, arr: string[]): boolean {
    let is = false;
    let res;
    const webapi = '/webapi/';
    for (let i = 0; i < arr.length; i++) {
      res = str.split(webapi)[1];
      res = `${webapi}${res}`;
      if (res === arr[i]) {
        is = true;
        break;
      }
    }
    return is;
  }

  private indexOf(str: string, arr: string[]): boolean {
    let is = false;
    for (let i = 0; i < arr.length; i++) {
      if (str.indexOf(arr[i]) > -1) {
        is = true;
        break;
      }
    }
    return is;
  }

  isUrlExcludedFromRetryStrategy(url: string): boolean {
    const arr: string[] = environment.excludedUrlsFromRetryStrategy;
    return this.indexOf(url, arr);
  }

  isMockRequest(url: string): number {
    let index = -1;
    if (environment.mock.enabled) {
      const arr: any[] = environment.mock.requests;

      for (let i = 0; i < arr.length; i++) {
        if (url.indexOf(arr[i].path) > -1) {
          index = i;
          break;
        }
      }
    }
    return index;
  }

  private getMockBaseUrl(hostname: string): string {
    const env = this.getEnviroment(hostname);
    return environment.mock.api[env];
  }

  private getUrlWithoutPathParams(url: string) {
    const arr: string[] = environment.mock.pathParamsShouldNotBeSentFor;
    for (const tmp of arr) {
      if (url.indexOf(tmp) > -1) {
        url = url.split(tmp)[0].concat(tmp);
        break;
      }
    }
    return url;
  }

  getMockUrl(url: string, hostname: string, root: string): string {
    url = this.getUrlWithoutPathParams(url);
    const arr = url.split('/webapi/');
    const mockBaseUrl = this.getMockBaseUrl(hostname);
    let mockUrl = `${mockBaseUrl}/webapi${root}${arr[1]}`;
    if(hostname === HOSTNAME.localhost){
      mockUrl = `${mockUrl}.json`;
    }
    return mockUrl;
  }

  getV1MockUrl(url: string, hostname: string): string {
    url = url.split('?')[0];
    const arr = url.split('/v1/');
    const mockBaseUrl = this.getMockBaseUrl(hostname);
    let mockUrl = `${mockBaseUrl}/webapi/v1/${arr[1]}`;
    if(hostname === HOSTNAME.localhost){
      mockUrl = `${mockUrl}.json`;
    }
    return mockUrl;
  }

  getMockRoot(index: number): string {
    return environment.mock.requests[index].root;
  }
}
